import { PlusIcon } from "@heroicons/react/outline";
import * as AWS from "aws-sdk";
import { format } from "date-fns";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import useBoolean from "../../utils/useBoolean";
import useStatus from "../../utils/useStatus";
import { Modal, ModalContent, ModalFooter } from "../ui/Modal";
import { SmallNotification } from "../ui/Notification";
import Spinner from "../ui/Spinner";

function getDateWithOffset(toSet, formatString = "yyyy-LL-dd") {
  const date = new Date(toSet);

  return format(
    new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)),
    formatString
  );
}

const dynamo = new AWS.DynamoDB({
  region: "us-east-1",
  secretAccessKey: "RiCC03NgsNxaXCyABD5nJhGNiK1lrtKxHt5ivCTu",
  accessKeyId: "AKIAZVWOKWYTOHKLQ7KG",
});

function getLookupDevices() {
  return new Promise((resolve, reject) => {
    dynamo.scan(
      {
        TableName: "lookup_table", // give it your table name
        Select: "ALL_ATTRIBUTES",
      },
      function (err, data) {
        if (err) reject(err);
        resolve(data);
      }
    );
  });
}

function createDevice(params) {
  return new Promise((resolve, reject) => {
    dynamo.putItem(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

function updateDevice(
  deviceID,
  updateExpression,
  expressionAttributeNames,
  expressionAttributeValues
) {
  return new Promise((resolve, reject) => {
    dynamo.updateItem(
      {
        TableName: "lookup_table", // give it your table name
        Key: { device_id: { S: deviceID } },
        UpdateExpression: updateExpression,
        ExpressionAttributeNames: {
          ...expressionAttributeNames,
        },
        ExpressionAttributeValues: {
          ...expressionAttributeValues,
        },
      },
      function (err, data) {
        if (err) reject(err);
        resolve(data);
      }
    );
  });
}

function Label({ children, label }) {
  return (
    <label htmlFor={label} className="block text-sm font-medium text-gray-700">
      {children}
    </label>
  );
}

const Input = forwardRef((props, ref) => {
  return (
    <input
      ref={ref}
      className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
      {...props}
    />
  );
});

function AddDevice({ add, toggle, addDevice }) {
  const [status, statusSetters] = useStatus("IDLE");
  const [created, { toggle: toggleNoti }] = useBoolean(false);
  const [failed, { toggle: toggleFailed }] = useBoolean(false);

  const formRef = useRef();

  async function handleSave() {
    try {
      statusSetters.loading();

      //reset notis
      if (failed) toggleFailed();
      if (created) toggleNoti();

      const elements = formRef.current.elements;

      const Item = {};
      for (const key of elements) {
        if (!key.id) continue;

        Item[key.id] = {
          S: key.value,
        };
      }

      await createDevice({
        TableName: "lookup_table",
        Item,
      });

      addDevice((prev) => [...prev, Item]);

      toggleNoti();

      statusSetters.resolved();
    } catch (error) {
      console.log(error);
      toggleFailed();
      statusSetters.failed();
    }
  }

  return (
    <Modal open={add} onClose={toggle}>
      <ModalContent
        title="add new device"
        Icon={<PlusIcon className="h-6 text-white" />}
      >
        <SmallNotification
          show={created}
          toggle={toggleNoti}
          title="New Device Added!"
          success
          timer
        />
        <SmallNotification
          show={failed}
          toggle={toggleFailed}
          title="Error adding device"
          error
          timer
        />
        <form
          id="create-form"
          ref={formRef}
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
          className="space-y-5"
        >
          <div className="grid grid-cols-2 gap-3">
            <Label label="Device ID">
              Device ID
              <Input type="text" id="device_id" name="device_id" />
            </Label>
            <Label label="Device Name">
              Device Name
              <Input type="text" id="friendly_name" name="friendly_name" />
            </Label>
            <Label label="Device Type">
              Device Type
              <Input type="text" id="device_type" name="device_type" />
            </Label>
            <Label label="Gateway ID">
              Gateway ID
              <Input type="text" id="gateway_id" name="gateway_id" />
            </Label>
          </div>
          <div className="grid grid-cols-4 gap-2">
            <Label label="Address">
              Address
              <Input type="text" id="address" name="address" />
            </Label>
            <Label label="City">
              City
              <Input type="text" id="city" name="city" />
            </Label>
            <Label label="State">
              State
              <Input type="text" id="state" name="state" />
            </Label>
            <Label label="Zip">
              Zip
              <Input type="text" id="zip" name="zip" />
            </Label>
            <Label label="Building Nam">
              Building Name
              <Input type="text" id="building_name" name="building_name" />
            </Label>
            <Label label="Floor Name">
              Floor Name
              <Input
                type="text"
                id="building_specific_location"
                name="building_specific_location"
              />
            </Label>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <Label label="start_date">
              Start Date
              <Input id="start_date" name="start_date" type="date" />
            </Label>
            <Label label="end_date">
              End Date
              <Input id="end_date" name="end_date" type="date" />
            </Label>
          </div>
        </form>
      </ModalContent>
      <ModalFooter>
        <button
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          type="submit"
          form="create-form"
          disabled={status === "LOADING"}
        >
          {status === "LOADING" ? "Saving" : "Save"}
          {status === "LOADING" && <Spinner />}
        </button>
        <button
          disabled={status === "LOADING"}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
}

function Info({ title, info }) {
  return (
    <p className="text-gray-500 text-sm">
      {title} <span className="text-gray-900">{info}</span>
    </p>
  );
}

function Device({ deviceInfo }) {
  const [status, statusSetters] = useStatus("IDLE");
  const originalInfo = useRef(deviceInfo);

  const friendlyNameRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();

  const [isUpdated, { toggle }] = useBoolean(false);

  const [success, { toggle: toggleSuccess }] = useBoolean(false);
  const [failed, { toggle: toggleFailed }] = useBoolean(false);

  function handleChange(e) {
    let hasUpdates = false;

    const { id, value } = e.target;

    //check value being passed in
    if (id.includes("date")) {
      if (
        getDateWithOffset(originalInfo.current[id].S, "yyyy-LL-dd") !== value
      ) {
        hasUpdates = true;
      }
    } else {
      if (value !== originalInfo.current[id].S) {
        hasUpdates = true;
      }
    }

    //check other keys
    const keys = ["start_date", "end_date", "friendly_name"].filter(
      (e) => e !== id
    );

    for (const key of keys) {
      if (key.includes("date")) {
        let ref;
        if (key === "start_date") {
          ref = startDateRef.current.value;
        } else {
          ref = endDateRef.current.value;
        }

        if (
          getDateWithOffset(originalInfo.current[key].S, "yyyy-LL-dd") !== ref
        ) {
          hasUpdates = true;
          break;
        }
      } else {
        if (friendlyNameRef.current.value !== originalInfo.current[key].S) {
          hasUpdates = true;
          break;
        }
      }
    }

    if (!isUpdated && hasUpdates) toggle();

    if (isUpdated && !hasUpdates) toggle();
  }

  async function handleSave() {
    try {
      statusSetters.loading();

      //reset notis
      if (success) toggleSuccess();
      if (failed) toggleFailed();

      //find what needs to be updated
      const updates = [];
      const keys = ["start_date", "end_date", "friendly_name"];

      for (const key of keys) {
        if (key.includes("date")) {
          let ref;
          if (key === "start_date") {
            ref = startDateRef.current.value;
          } else {
            ref = endDateRef.current.value;
          }

          if (
            getDateWithOffset(originalInfo.current[key].S, "yyyy-LL-dd") !== ref
          ) {
            updates.push({
              name: key,
              value: getDateWithOffset(ref, "LL/dd/yyyy"),
            });
          }
        } else {
          if (friendlyNameRef.current.value !== originalInfo.current[key]) {
            updates.push({
              name: key,
              value: friendlyNameRef.current.value,
            });
          }
        }
      }

      //build expression
      const stringExpression = updates
        .map(
          (update, idx) =>
            `${idx === 0 ? "set" : ""} #${update.name} = :${update.name}`
        )
        .join(",");

      //build values and names, update local info
      const expressionNames = {};
      const expressionValues = {};
      for (const update of updates) {
        expressionNames[`#${update.name}`] = update.name;
        expressionValues[`:${update.name}`] = { S: update.value };

        originalInfo.current[update.name] = { S: update.value };
      }

      await updateDevice(
        deviceInfo.device_id.S,
        stringExpression,
        expressionNames,
        expressionValues
      );

      toggle();
      toggleSuccess();

      statusSetters.resolved();
    } catch (error) {
      console.log(error);
      toggleFailed();
      statusSetters.failed();
    }
  }

  return (
    <div className="bg-white rounded px-6 shadow space-y-6 pb-4">
      <SmallNotification
        show={success}
        toggle={toggleSuccess}
        title="Device Updated!"
        success
        timer
      />
      <SmallNotification
        show={failed}
        toggle={toggleFailed}
        title="Error updating device"
        error
        timer
      />
      <div className="grid grid-cols-1 gap-2">
        <Info title="Device ID:" info={deviceInfo.device_id.S} />
        <Info title="Gateway ID:" info={deviceInfo.gateway_id.S} />
        <Info
          title="Address:"
          info={`${deviceInfo.address.S}, ${deviceInfo.city.S}, ${deviceInfo.state.S} ${deviceInfo.zip.S}`}
        />
        <Info title="Building:" info={deviceInfo.building_name.S} />
        <Info
          title="Building Location:"
          info={deviceInfo.building_specific_location.S}
        />
      </div>

      <form
        id="edit-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
        className="space-y-4"
      >
        <Label label="Device Name">
          Device Name
          <Input
            type="text"
            id="friendly_name"
            name="friendly_name"
            defaultValue={deviceInfo.friendly_name.S}
            onChange={handleChange}
            ref={friendlyNameRef}
          />
        </Label>

        <div className="flex space-x-2">
          <Label label="start_date">
            Start Date
            <Input
              id="start_date"
              name="start_date"
              type="date"
              defaultValue={getDateWithOffset(
                deviceInfo.start_date.S,
                "yyyy-LL-dd"
              )}
              onChange={handleChange}
              ref={startDateRef}
            />
          </Label>
          <Label label="end_date">
            End Date
            <Input
              id="end_date"
              name="end_date"
              type="date"
              defaultValue={getDateWithOffset(
                deviceInfo.end_date.S,
                "yyyy-LL-dd"
              )}
              onChange={handleChange}
              ref={endDateRef}
            />
          </Label>
        </div>

        {isUpdated && (
          <button
            type="submit"
            className="w-full text-center bg-green-700 text-white rounded py-2 px-2 text-sm flex justify-center mt-3"
            disabled={status === "LOADING"}
          >
            {status === "LOADING" ? "Saving" : "Save"}
            {status === "LOADING" && <Spinner />}
          </button>
        )}
      </form>
    </div>
  );
}
function EditDevices() {
  const [status, statusSetters] = useStatus("LOADING");
  const [devices, setDevices] = useState([]);

  const [add, { toggle }] = useBoolean(false);

  useEffect(() => {
    async function getDevices() {
      try {
        const { Items } = await getLookupDevices();
        setDevices(Items);

        statusSetters.resolved();
      } catch (error) {
        console.log(error);
        statusSetters.failed();
      }
    }

    getDevices();
  }, [statusSetters]);

  if (status === "LOADING") {
    return (
      <div className="w-9/12 mx-auto flex flex-col justify-center items-center space-y-2 bg-white mt-12">
        <p className="italic text-gray-600 font-medium">Fetching Devices</p>
        <svg
          className={`animate-spin ml-2 h-8 w-8 text-green-500
       
      }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <div className="py-6 relative">
      <AddDevice add={add} toggle={toggle} addDevice={setDevices} />
      <div className="inline-flex flex-col space-y-2 py-2 px-2 ml-2 sticky top-0">
        <h1 className="text-gray-900 text-xl font-medium">Active Devices</h1>
        <button
          type="button"
          onClick={toggle}
          className="bg-green-700 text-white py-2 px-2 rounded text-sm"
        >
          Add New Device
        </button>
      </div>
      <div className="flex flex-wrap gap-2 justify-items-start">
        {devices.map((device) => (
          <Device key={device.device_id.S} deviceInfo={device} />
        ))}
      </div>
    </div>
  );
}

export default function Configure() {
  return (
    <Routes>
      <Route path="home" element={<EditDevices />} />
      <Route path="add" element={<AddDevice />} />
    </Routes>
  );
}
