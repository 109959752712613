import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import logo from "../../images/aqua.png";
import Spinner from "../ui/Spinner";

export default function ForgotPassword() {
  const { sendForgotPasswordEmail } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [resolved, setResolved] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,

    setError,
    formState: { errors },
  } = useForm();

  async function onSubmit(data) {
    try {
      setLoading(true);
      await sendForgotPasswordEmail(data.email);
      setLoading(false);
      setResolved(true);
      navigate("/auth/verification", { state: { email: data.email } });
    } catch (error) {
      setError("email", {
        type: "manual",
        message: error,
      });

      setLoading(false);
    }
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="h-36 w-auto mx-auto"
            src={logo}
            alt="Aquatherm Logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Forgot password
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Enter your email address below to begin
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    {...register("email")}
                    className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm ${
                      errors.email
                        ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                        : "focus:ring-green-500 focus:border-green-500 border-gray-300"
                    }`}
                  />
                  {errors.email ? (
                    <p className="text-red-700">{errors.email.message}</p>
                  ) : null}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  {loading ? (
                    <Spinner color="white" />
                  ) : resolved ? (
                    "Email Sent!"
                  ) : (
                    "Send Email"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
