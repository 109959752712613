import * as AWS from "aws-sdk";
import axios from "axios";
import { format } from "date-fns";

const docClient = new AWS.DynamoDB.DocumentClient({
  region: "us-east-1",
  secretAccessKey: "RiCC03NgsNxaXCyABD5nJhGNiK1lrtKxHt5ivCTu",
  accessKeyId: "AKIAZVWOKWYTOHKLQ7KG",
});

async function getLocationDevices(building, city, state) {
  return new Promise((resolve, reject) => {
    docClient.scan(
      {
        TableName: "lookup_table",
        FilterExpression: "city = :city and #place_state = :stateName",

        ExpressionAttributeValues: {
          ":city": city,
          ":stateName": state,
        },
        ExpressionAttributeNames: {
          "#place_state": "state",
        },
      },
      function (err, data) {
        if (err) reject(err);
        resolve(data.Count);
      }
    );
  });
}

function getDeviceData(deviceId) {
  const formattedStart = format(new Date(), "yyyy-LL-dd");

  return new Promise((resolve, reject) => {
    docClient.scan(
      {
        TableName: "updated_aquatherm",
        FilterExpression:
          "contains(message.message, :deviceId) and message.ts between :start_ts and :end_ts",

        ExpressionAttributeValues: {
          ":deviceId": deviceId,
          ":start_ts": `${formattedStart} 00:00:01`,
          ":end_ts": `${formattedStart} 00:00:51`,
        },
      },
      function (err, data) {
        if (err) reject(err);

        resolve(data.Count);
      }
    );
  });
}

export default async function parseLocations(res) {
  const places = [];

  for (const item of res.Items) {
    //check if a location of the same city
    const idx = places.findIndex((location) => location.city === item.city);
    // idx === -1 create the location
    if (idx === -1) {
      //get device count
      const deviceCount = await getLocationDevices(
        item.building_name,
        item.city,
        item.state
      );
      //get lat long
      const {
        data: { features: data },
      } = await axios.get(
        `https://murmuring-reef-40492.herokuapp.com/https://nominatim.openstreetmap.org/search?city=${item.city}&state=${item.state}&country=united+states&format=geocodejson`
      );

      //get device status
      let deviceStatus;
      const deviceData = await getDeviceData(item.device_id);
      if (deviceData === 0) {
        deviceStatus = 2;
      } else {
        deviceStatus = 1;
      }

      places.push({
        city: item.city,
        state: item.state,
        worstStatus: deviceStatus,
        buildings: {
          [item.building_name]: {
            title: item.building_name,
            deviceCount,

            lat: data[0].geometry.coordinates[1],
            lng: data[0].geometry.coordinates[0],
            floors: {
              [item.building_specific_location]: {
                navTitle: item.building_specific_location.replace(" ", "-"),
                devices: [
                  {
                    worstStatus: deviceStatus,
                    device_id: item.device_id,
                    friendly_name: item.friendly_name,
                  },
                ],
              },
            },
          },
        },
      });
    } else {
      let deviceStatus;
      const deviceData = await getDeviceData(item.device_id);
      if (deviceData === 0) {
        deviceStatus = 2;
      } else {
        deviceStatus = 1;
      }

      //check if building already exists, if so push onto its floor
      if (item.building_name in places[idx].buildings) {
        //check if floor exists, if so push to its devices

        if (
          item.building_specific_location in
          places[idx].buildings[item.building_name].floors
        ) {
          places[idx].buildings[item.building_name].floors[
            item.building_specific_location
          ].devices.push({
            worstStatus: deviceStatus,

            device_id: item.device_id,
            friendly_name: item.friendly_name,
          });

          places[idx].worstStatus =
            places[idx].worstStatus > deviceStatus
              ? places[idx].worstStatus
              : deviceStatus;
        } else {
          places[idx].worstStatus =
            places[idx].worstStatus > deviceStatus
              ? places[idx].worstStatus
              : deviceStatus;
          places[idx].buildings[item.building_name].floors = {
            ...places[idx].buildings[item.building_name].floors,
            floors: {
              [item.building_specific_location]: {
                navTitle: item.building_specific_location.replace(" ", "-"),
                devices: [
                  {
                    worstStatus: deviceStatus,
                    navTitle: item.building_specific_location.replace(" ", "-"),
                    device_id: item.device_id,
                    friendly_name: item.friendly_name,
                  },
                ],
              },
            },
          };
        }
      } else {
        //get lat long
        const {
          data: { features: data },
        } = await axios.get(
          `https://murmuring-reef-40492.herokuapp.com/https://nominatim.openstreetmap.org/search?city=${item.city}&state=${item.state}&country=united+states&format=geocodejson`
        );

        //get device count
        const deviceCount = await getLocationDevices(
          item.building_name,
          item.city,
          item.state
        );

        places[idx].worstStatus =
          places[idx].worstStatus > deviceStatus
            ? places[idx].worstStatus
            : deviceStatus;

        places[idx].buildings = {
          ...places[idx].buildings,
          [item.building_name]: {
            title: item.building_name,
            deviceCount,
            lat: data[0].geometry.coordinates[1],
            lng: data[0].geometry.coordinates[0],
            floors: {
              [item.building_specific_location]: {
                devices: [
                  {
                    worstStatus: deviceStatus,
                    navTitle: item.building_specific_location.replace(" ", "-"),
                    device_id: item.device_id,
                    friendly_name: item.friendly_name,
                  },
                ],
              },
            },
          },
        };
      }
    }
  }

  const buildings = [];

  for (const location of places) {
    for (const b in location.buildings) {
      buildings.push(location.buildings[b]);
    }
  }
  return {
    places,
    buildings,
  };
}
