import axios from "axios";
import React, { createContext } from "react";

const FetchContext = createContext();
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
  const publicAxios = axios.create({
    baseURL: "http://localhost:5000",
    withCredentials: false,
  });
  const authAxios = axios.create({
    baseURL: "http://localhost:5000",
    withCredentials: false,
  });

  publicAxios.interceptors.response.use(
    (response) => {
      return response;
    },

    (error) => {
      return Promise.reject(error.response.data.data);
    }
  );
  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },

    (error) => {
      return Promise.reject(error.response.data?.message[0].messages);
    }
  );

  return (
    <Provider
      value={{
        authAxios,
        publicAxios,
      }}
    >
      {children}
    </Provider>
  );
};

export { FetchContext, FetchProvider };
