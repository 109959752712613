import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import logo from "../../images/aqua.png";
import Spinner from "../ui/Spinner";

export default function ChangePassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const { changePassword, changePasswordChallenge } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,

    setError,
    formState: { errors },
  } = useForm();

  async function onSubmit(data) {
    if (data.password !== data.new_password) {
      setError("password", {
        type: "manual",
        message: "Passwords must match",
      });
      setError("new_password", {
        type: "manual",
        message: "Passwords must match",
      });

      return;
    }
    try {
      setLoading(true);
      if (location.state?.password) {
        //user is logging in for first time and needs to set a new password
        await changePasswordChallenge(
          location.state.email,
          location.state.password,
          data.password
        );
      } else {
        await changePassword(data.old_password, data.password);
      }
      setLoading(false);
      navigate("/locations");
    } catch (error) {
      setError("password", {
        type: "manual",
        message: error,
      });

      setError("new_password", {
        type: "manual",
        message: error,
      });
      setLoading(false);
    }
  }

  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-36 w-auto" src={logo} alt="Aquatherm Logo" />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Change Password
            </h2>
            {location.state?.password ? (
              <p className="mt-2 text-sm text-gray-600">
                To sign in you must set a new password
              </p>
            ) : null}
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                {!location.state?.password ? (
                  <div className="space-y-1">
                    <label
                      htmlFor="old_password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Old Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="old_password"
                        name="old_password"
                        type="password"
                        required
                        {...register("old_password")}
                        className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm ${
                          errors.old_password
                            ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                            : "focus:ring-green-500 focus:border-green-500 border-gray-300"
                        }`}
                      />
                      {errors.old_password ? (
                        <p className="text-red-700">
                          {errors.old_password.message}
                        </p>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="space-y-1">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    New Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      {...register("password")}
                      className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm ${
                        errors.password
                          ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                          : "focus:ring-green-500 focus:border-green-500 border-gray-300"
                      }`}
                    />
                    {errors.password ? (
                      <p className="text-red-700">{errors.password.message}</p>
                    ) : null}
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="new_password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Confirm New Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="new_password"
                      name="new_password"
                      type="password"
                      required
                      {...register("new_password")}
                      className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm ${
                        errors.password
                          ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                          : "focus:ring-green-500 focus:border-green-500 border-gray-300"
                      }`}
                    />
                    {errors.new_password ? (
                      <p className="text-red-700">
                        {errors.new_password.message}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    {loading ? <Spinner color="white" /> : "Change Password"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
          alt=""
        />
      </div>
    </div>
  );
}
