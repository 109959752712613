import { Browser, enableRipple } from "@syncfusion/ej2-base";
import {
  Category,
  ChartComponent,
  Crosshair,
  DateTime,
  Inject,
  Legend,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
/**
 * Sample for Line Series with dashed line
 */
import * as React from "react";

enableRipple(true);

class SampleBase extends React.PureComponent {
  rendereComplete() {
    /**custom render complete function */
  }
  componentDidMount() {
    setTimeout(() => {
      this.rendereComplete();
    });
  }
}

export class Line extends SampleBase {
  render() {
    return (
      <div className="w-full h-full">
        <style>
          {`
    .control-fluid {
		padding: 0px !important;
    }
    #${this.props.id}_Series_2,
    #${this.props.id}_Series_1,
    #${this.props.id}_Series_0 {
         
        stroke-linejoin: round; stroke-linecap: round;
        
    }
 
 `}
        </style>
        <div className="w-full h-full">
          <ChartComponent
            id={this.props.id}
            enable
            style={{ textAlign: "center", width: "100%", height: "100%" }}
            primaryXAxis={this.props.xAxisOptions}
            primaryYAxis={this.props.yAxisOptions}
            load={this.load.bind(this)}
            chartArea={{ border: { width: 0 } }}
            tooltip={{ enable: true, shared: true }}
            crosshair={{
              enable: true,
              line: {
                color: "rgba(204,214,235,0.25)",
                width: Browser.isDevice ? 50 : 20,
              },
              lineType: "Vertical",
            }}
            legendSettings={{ visible: true }}
            height={"100%"}
            width={"100%"}
            loaded={this.onChartLoad.bind(this)}
          >
            <Inject
              services={[
                LineSeries,
                DateTime,
                Category,
                Legend,
                Tooltip,
                Crosshair,
              ]}
            />

            <SeriesCollectionDirective>
              {this.props.dataSets !== undefined &&
                Object.keys(this.props.dataSets).map((e, idx) => (
                  <SeriesDirective
                    key={this.props.dataSets[e]}
                    dataSource={this.props.dataSets[e][this.props.type]}
                    xName="x"
                    yName="y"
                    name={this.props.titles[idx]}
                    width={2}
                    marker={{ visible: true, width: 10, height: 10 }}
                    type="Line"
                  ></SeriesDirective>
                ))}
            </SeriesCollectionDirective>
          </ChartComponent>
        </div>
      </div>
    );
  }
  onChartLoad(args) {
    let chart = document.getElementById(this.props.id);
    chart.setAttribute("title", "");
  }
  load(args) {
    //eslint-disable-next-line
    let selectedTheme = location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    ).replace(/-dark/i, "Dark");
  }
}
