import * as AWS from "aws-sdk";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import parseLocations from "../../utils/parseLocations";
import Leaflet from "./Leaflet";
import Sidebar from "./Sidebar";

const docClient = new AWS.DynamoDB.DocumentClient({
  region: "us-east-1",
  secretAccessKey: "RiCC03NgsNxaXCyABD5nJhGNiK1lrtKxHt5ivCTu",
  accessKeyId: "AKIAZVWOKWYTOHKLQ7KG",
});

function get(startDate, endDate) {
  return new Promise((resolve, reject) => {
    docClient.scan(
      {
        TableName: "lookup_table",
      },
      function (err, data) {
        if (err) reject(err);

        resolve(data);
      }
    );
  });
}

export default function TestWrapper() {
  const location = useLocation();

  const [selected, setSelected] = useState(
    location.state?.selected
      ? location.state.selected
      : {
          location: "",
          buildings: [{ name: "", deviceCount: 0 }],
        }
  );

  const [locations, setLocations] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [status, setStatus] = useState("FETCHING");
  const [center, setCenter] = useState([39.09366509575983, -94.58751660204751]);
  const [zoom, setZoom] = useState(4);
  const [count, setCount] = useState(0);

  const memo = useMemo(() => [count, setCount], [count]);
  const memoSelect = useMemo(
    () => [selected, setSelected],
    [selected, setSelected]
  );

  useEffect(() => {
    async function getInitial() {
      try {
        const res = await get();

        //track this for handling refreshing locations
        setCount(res.Count);
        const { places, buildings } = await parseLocations(res);

        setBuildings(buildings);
        setLocations(places);

        setStatus("RESOLVED");
      } catch (error) {
        setTimeout(() => {
          ///wait two seconds
        }, 2000);
      }
    }

    getInitial();
  }, []);

  async function handleRefresh() {
    try {
      setStatus("REFRESHING");
      const res = await get();
      //if the count is larger than the current count, add in new location/device
      if (res.Count !== count) {
        setCount(res.Count);

        const { places, buildings } = await parseLocations(res);
        setLocations(places);
        setBuildings(buildings);
      }
      setStatus("REFRESHED");
    } catch (error) {}
  }

  return (
    <div className="flex justify-around py-4">
      <div className="w-7/12 bg-white rounded-lg p-2">
        {status === "FETCHING" || status === "REFRESHING" ? (
          <div className="flex flex-col justify-center items-center space-y-2">
            <p className="italic text-gray-600 font-medium">
              Fetching Locations
            </p>
            <svg
              className={`animate-spin ml-2 h-8 w-8 text-green-500
       
      }`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        ) : null}
        {status === "RESOLVED" || status === "REFRESHED" ? (
          <Leaflet
            buildings={buildings}
            selected={memoSelect[0]}
            setSelected={memoSelect[1]}
            center={center}
            zoom={zoom}
            count={memo[0]}
          />
        ) : null}
      </div>

      {status === "RESOLVED" || status === "REFRESHED" ? (
        <Sidebar
          handleRefresh={handleRefresh}
          status={status}
          locations={locations}
          setCenter={setCenter}
          setZoom={setZoom}
          setCount={memo[1]}
          selected={memoSelect[0]}
          setSelected={memoSelect[1]}
        />
      ) : null}
    </div>
  );
}
