import { ArrowNarrowLeftIcon, RefreshIcon } from "@heroicons/react/solid";
import React from "react";
import { useNavigate } from "react-router-dom";

function Location({ location, amount, color, onClick }) {
  return (
    <button
      onClick={onClick}
      className="flex items-center justify-between cursor-pointer mb-2"
    >
      <p className="text-gray-900 font-medium mr-3">{location}</p>
      <p className={`text-${color}-500 italic`}>{amount} locations</p>
    </button>
  );
}

function Sidebar({
  locations,
  setCount,
  setCenter,
  setZoom,
  selected,
  setSelected,
  handleRefresh,
  status,
}) {
  const navigate = useNavigate();

  function handleNavigate(selected, e) {
    const lowerCase = e.title.toLowerCase().replace(" ", "-");
    const building = selected.buildings[e.title];

    const floors = building.floors;

    navigate(lowerCase, {
      state: {
        selected,
        floors,
        building: building.title,
      },
    });
  }

  //TODO: bug when clicking on location sidebar initially

  return (
    <div className="w-64 bg-white rounded-lg shadow px-2 py-2 flex flex-col justify-between">
      <div>
        <div className="flex justify-between items-center">
          {selected.location !== "" && (
            <button
              onClick={() => {
                setSelected({
                  location: "",
                  buildings: [{ name: "", deviceCount: 0 }],
                });
                setCenter([39.09366509575983, -94.58751660204751]);
                setZoom(4);
                setCount((c) => c + 1);
              }}
              className="flex items-center space-x-2 mb-4"
            >
              <ArrowNarrowLeftIcon className="h-4 w-4 text-gray-500" />
              <p className="text-sm text-gray-500">Go Back</p>
            </button>
          )}
          <h2 className="text-gray-900 font-bold">
            {selected.location !== "" ? selected.location : "Locations"}
          </h2>
          <button
            onClick={handleRefresh}
            className="text-sm text-gray-500 flex items-center space-x-2"
          >
            Refresh
            <RefreshIcon
              className={
                status === "REFRESHING"
                  ? "h-4 w-4 ml-2 animate-spin"
                  : "h-4 w-4 ml-2"
              }
            />
          </button>
        </div>

        {selected.location !== ""
          ? Object.keys(selected.buildings).map((b) => (
              <button
                key={selected.buildings[b].device_id}
                onClick={() => handleNavigate(selected, selected.buildings[b])}
                className="flex items-center justify-between"
              >
                <p className="font-medium text-gray-900 mr-4">
                  {selected.buildings[b].title}
                </p>
                <p
                  className={`italic text-${
                    selected.worstStatus === 1
                      ? "green"
                      : selected.worstStatus === 2
                      ? "yellow"
                      : "red"
                  }-500 font-medium`}
                >
                  {selected.buildings[b].deviceCount} Devices
                </p>
              </button>
            ))
          : locations.map((location) => (
              <Location
                key={location.lat}
                location={`${location.city},${location.state}`}
                amount={Object.keys(location.buildings).length}
                color={
                  location.worstStatus === 1
                    ? "green"
                    : location.worstStatus === 2
                    ? "yellow"
                    : "red"
                }
                onClick={() => {
                  setSelected(location);
                }}
              />
            ))}
      </div>

      <div className="flex justify-center items-center space-x-3">
        <span className="rounded py-1 px-2 font-medium text-white bg-green-500">
          Good
        </span>
        <span className="rounded py-1 px-2 font-medium text-white bg-yellow-500">
          Issues
        </span>
        <span className="rounded py-1 px-2 font-medium text-white bg-red-500">
          Critical
        </span>
      </div>
    </div>
  );
}

export default Sidebar;
