import { Auth } from "aws-amplify";
import React, { createContext, useEffect, useState } from "react";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    email: "",
  });

  async function login(email, password) {
    try {
      const user = await Auth.signIn(email, password);

      if (
        user.challengeName &&
        user.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        return {
          redirect: true,
        };
      } else {
        setAuthState({ isAuthenticated: true, email });
      }
    } catch (error) {
      throw error.message;
    }
  }

  async function changePasswordChallenge(email, password, newPassword) {
    try {
      const user = await Auth.signIn(email, password);

      await Auth.completeNewPassword(user, newPassword);

      setAuthState(() => ({
        isAuthenticated: true,
        email,
      }));
    } catch (error) {
      throw error.message;
    }
  }

  async function changePassword(oldPassword, newPassword) {
    try {
      const user = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(user, oldPassword, newPassword);

      setAuthState((prev) => ({
        isAuthenticated: true,
        email: user.attributes.email,
      }));
    } catch (error) {
      console.log(error);
      throw error.message;
    }
  }

  async function sendForgotPasswordEmail(username) {
    try {
      await Auth.forgotPassword(username);
    } catch (error) {
      throw error.message;
    }
  }

  async function forgotPassword(username, code, newPassword) {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      await Auth.signIn(username, newPassword);

      setAuthState(() => ({
        isAuthenticated: true,
        email: username,
      }));
    } catch (error) {
      throw error.message;
    }
  }

  async function logout() {
    try {
      await Auth.signOut();
      setAuthState({ isAuthenticated: false, email: "" });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function getUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();

        setAuthState({ isAuthenticated: true, email: user.attributes.email });
      } catch (error) {
        setAuthState({ isAuthenticated: false, email: "" });
      }
    }
    getUser();
  }, []);
  return (
    <Provider
      value={{
        authState,
        setAuthState,
        login,
        logout,
        changePassword,
        changePasswordChallenge,
        sendForgotPasswordEmail,
        forgotPassword,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
