import React from "react";
import { Route, Routes } from "react-router-dom";
import Configure from "./Configure";
import Locations from "./Locations";
import Report from "./Report";
import AppWrapper from "./ui/AppWrapper";

export default function AuthenticatedAppRouter() {
  return (
    <AppWrapper>
      <Routes>
        <Route path="locations/*" element={<Locations />} />
        <Route path="reporting" element={<Report />} />
        <Route path="configuration/*" element={<Configure />} />
      </Routes>
    </AppWrapper>
  );
}
