import React from "react";
import { Route, Routes } from "react-router-dom";
import Building from "./Building";
import TestWrapper from "./TestWrapper";

export default function Locations() {
  //im the locations placeholder
  return (
    <Routes>
      <Route path="/" element={<TestWrapper />} />
      <Route path="/*" element={<Building />} />
    </Routes>
  );
}
