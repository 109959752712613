import React, { useContext } from "react";
import { Navigate, Route } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

function RouteElement({ element, unauthenticated, dashboard }) {
  const { authState } = useContext(AuthContext);
  const { isAuthenticated } = authState;

  if (!isAuthenticated && !unauthenticated) {
    return <Navigate to="/auth/login" />;
  }

  if (isAuthenticated && unauthenticated) {
    return <Navigate to="/locations" />;
  }
  return element ?? null;
}

export default function PrivateRoute({
  element,
  unauthenticated,
  dashboard,
  ...rest
}) {
  return (
    <Route
      {...rest}
      element={
        <RouteElement
          element={element}
          unauthenticated={unauthenticated}
          dashboard={dashboard}
        />
      }
    />
  );
}
