import L from "leaflet";
import React from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useNavigate } from "react-router";

function TestMarker({ buildings, setSelected, selected, locations }) {
  const navigate = useNavigate();

  function clusterIconCreate(cluster) {
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: "marker-cluster-custom",
      iconSize: L.point(40, 40, true),
    });
  }

  return (
    <MarkerClusterGroup
      iconCreateFunction={clusterIconCreate}
      onClick={(e) => {
        const zz = locations.find((place) => {
          const x = String(place.lat).slice(0, 2);

          return x.includes(String(e.latlng.lat).slice(0, 2));
        });
        setSelected(zz);
      }}
    >
      {buildings.map((e) => (
        <Marker
          key={e.lat}
          position={[e.lat, e.lng]}
          eventHandlers={{
            add: (e) => {
              /**user scrolled into a cluster */

              //only set if there isnt already selected
              if (selected.title === "") {
                const { lat } = e.sourceTarget._latlng;

                const filteredLocation = locations.find((place) => {
                  const x = String(place.lat).slice(0, 2);

                  return x.includes(String(lat).slice(0, 2));
                });

                setSelected(filteredLocation);
              }
            },

            remove: () => {
              /**user is scrolling out of a cluster */
              if (selected.title && selected.title === "") return;
              if (selected.city) {
                // setSelected({
                //   location: "",
                //   buildings: [{ name: "", deviceCount: 0 }],
                // });
              }
            },
            click: (e) => {
              const building = buildings.find(
                (building) => building.lat === e.latlng.lat
              );

              const lowerCase = building.title.toLowerCase().replace(" ", "-");
              const floors = building.floors;

              navigate(lowerCase, {
                state: {
                  selected,
                  floors,
                  building: building.title,
                },
              });
            },
          }}
        />
      ))}
    </MarkerClusterGroup>
  );
}

export default function Leaflet({ buildings, selected, setSelected, count }) {
  return (
    <MapContainer
      className="markercluster-map"
      center={[39.09366509575983, -94.58751660204751]}
      minZoom={4}
      zoom={4}
      maxZoom={18}
    >
      {/* <ChangeView count={count} /> */}
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <TestMarker
        locations={buildings}
        buildings={buildings}
        setSelected={setSelected}
        selected={selected}
      />
    </MapContainer>
  );
}
