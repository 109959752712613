import { ChevronLeftIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { IconContext } from "react-icons";
import { BiRadio } from "react-icons/bi";
import { CgSmartphoneShake } from "react-icons/cg";
import { FaThermometerHalf } from "react-icons/fa";
import { FiMonitor } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import heart from "../../images/Path-25.png";
import Select from "../Select";
import WarningModal from "../ui/WarningModal";
import DynamicLine from "./DynamicLine";

function DeviceIcon({ type, color }) {
  let icon;

  if (type === "Temperature") {
    icon = <FaThermometerHalf />;
  } else if (type === "Vibration") {
    icon = <CgSmartphoneShake />;
  } else if (type === "Pressure") {
    icon = <FiMonitor />;
  } else if (type === "Flow") {
    icon = <BiRadio />;
  }

  return (
    <div className="relative">
      <IconContext.Provider value={{ className: "text-gray-600 h-11 w-11" }}>
        {icon}
      </IconContext.Provider>
      <div
        className={`h-4 w-4 bg-${color}-500 rounded-full absolute bottom-0 right-0`}
      ></div>
    </div>
  );
}
function DeviceCard({
  title,
  Icon,
  lineChartID,
  type,
  deviceId,
  unitType,
  friendly_name,
  status,
}) {
  //eslint-disable-next-line
  const [enabled, setEnabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(
    status === 1 ? "green" : status === 2 ? "yellow" : "red"
  );
  const [selectedMeasure, setSelectedMeasure] = useState(
    friendly_name.toLowerCase() === "IFM Temp"
      ? measureOptionsTemp[0]
      : friendly_name.toLowerCase() === "IFM Flow"
      ? measureOptionsFlow[0]
      : friendly_name.toLowerCase() === "Pressure"
      ? measureOptionsPressure[0]
      : measureOptionsVibration[0]
  );

  function handleCancel() {
    setEnabled(false);
    setOpen(false);
    setColor("red");
  }
  return (
    <>
      <WarningModal open={open} setOpen={setOpen} handleCancel={handleCancel} />
      <div
        style={{ height: "600px" }}
        className="bg-white rounded-lg shadow-lg px-8 flex flex-col justify-between py-6"
      >
        <div className="flex space-x-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <DeviceIcon type={type} color={color} />
              <p className="text-gray-900 font-medium text-lg">
                {title} {deviceId}
              </p>
              <p className="text-gray-900 font-medium text-lg">
                {title} {deviceId}
              </p>
            </div>
          </div>
          <div className="w-64">
            <Select
              labelText="Unit Type"
              selected={selectedMeasure}
              setSelected={setSelectedMeasure}
              options={
                friendly_name.toLowerCase() === "IFM Temp"
                  ? measureOptionsTemp
                  : friendly_name.toLowerCase() === "IFM Flow"
                  ? measureOptionsFlow
                  : friendly_name.toLowerCase() === "Pressure"
                  ? measureOptionsPressure
                  : measureOptionsVibration
              }
            />
          </div>
        </div>
        <p className="text-gray-500">Last 30 seconds</p>

        {type === "Temperature" ? (
          <DynamicLine
            type={type}
            deviceId={deviceId}
            selectedMeasure={selectedMeasure}
          />
        ) : type === "Flow" ? (
          <DynamicLine
            type={type}
            deviceId={deviceId}
            unitType={unitType}
            selectedMeasure={selectedMeasure}
          />
        ) : type === "Pressure" ? (
          <DynamicLine
            type={type}
            deviceId={deviceId}
            unitType={unitType}
            selectedMeasure={selectedMeasure}
          />
        ) : (
          <DynamicLine
            type={type}
            deviceId={deviceId}
            unitType={unitType}
            selectedMeasure={selectedMeasure}
          />
        )}

        <div className="flex items-center space-x-4">
          <img className="h-12" src={heart} alt="heart" />
          <p className="italic text-sm text-gray-500">Every 1 Second</p>
        </div>
      </div>
    </>
  );
}

const measureOptionsTemp = [
  { id: 0, name: "Fahrenheit" },
  { id: 1, name: "Celsius" },
  { id: 2, name: "Voltage" },
];

// const deviceOptions = [
//   { id: 0, name: "Temperature" },
//   { id: 1, name: "Pressure" },
//   { id: 2, name: "Flow" },
//   { id: 3, name: "Vibration" },
// ];

const measureOptionsPressure = [
  { id: 0, name: "PSI" },
  { id: 1, name: "Pascal" },
];

const measureOptionsFlow = [
  { id: 0, name: "Voltage" },
  { id: 1, name: "MPS" },
];

const measureOptionsVibration = [
  { id: 0, name: "HZ x-axis" },
  { id: 1, name: "HZ y-axis" },
  { id: 2, name: "HZ z-axis" },
  { id: 3, name: "mms x-axis" },
  { id: 4, name: "mms y-axis" },
  { id: 5, name: "mms x-axis" },
  { id: 6, name: "crest factor x-axis" },
  { id: 7, name: "crest factor y-axis" },
  { id: 8, name: "crest factor z-axis" },
  { id: 9, name: "temperature fahrenheit" },
  { id: 10, name: "temperature celsius" },
  { id: 11, name: "Duty Cycle" },
];

export default function Floor() {
  const location = useLocation();

  const navigate = useNavigate();

  return (
    <div className="px-6 py-6">
      <div className="flex items-center justify-between">
        <button
          className="capitalize flex items-center text-gray-500"
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon className="h-5 w-5" />
          Back to {location.pathname.split("/")[2].replace("-", " ")}
        </button>

        <div className="flex items-center space-x-12">
          <h2 className="capitalize text-gray-700 text-lg font-bold">
            {location.state.floor.navTitle} -{" "}
            {location.state.building.replace("-", " ")}
          </h2>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-2 gap-3">
        {location.state.floor.devices.map((device) => {
          return (
            <>
              {device.friendly_name === "IFM Temp" && (
                <DeviceCard
                  lineChartID="Temperature"
                  title="Temperature"
                  type="Temperature"
                  deviceId={device.device_id}
                  friendly_name={device.friendly_name}
                  status={device.worstStatus}
                />
              )}

              {device.friendly_name === "Vibration Meter" && (
                <DeviceCard
                  lineChartID="Vibration"
                  title="Vibration"
                  type="Vibration"
                  deviceId={device.device_id}
                  friendly_name={device.friendly_name}
                  status={device.worstStatus}
                />
              )}
              {device.friendly_name === "Pressure" && (
                <DeviceCard
                  lineChartID="Pressure"
                  title="Pressure"
                  type="Pressure"
                  deviceId={device.device_id}
                  friendly_name={device.friendly_name}
                  status={device.worstStatus}
                />
              )}
              {device.friendly_name === "IFM Flow" && (
                <DeviceCard
                  lineChartID="Flow"
                  title="Flow"
                  type="Flow"
                  deviceId={device.device_id}
                  friendly_name={device.friendly_name}
                  status={device.worstStatus}
                />
              )}
            </>
          );
        })}
      </div>
    </div>
  );
}
