import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth from "./Components/Auth/index";
import AuthenticatedAppRouter from "./Components/AuthenticatedAppRouter";
import PrivateRoute from "./Components/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import { FetchProvider } from "./context/FetchContext";

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:f2bfd11c-873e-49a2-a8d8-50b2a9b62052",
    region: "us-east-1",
    userPoolId: "us-east-1_uWEOlR0oh",
    userPoolWebClientId: "3rehld181mb2mukkv4d5cd8tea",
  },
});

function App() {
  return (
    <BrowserRouter>
      <FetchProvider>
        <AuthProvider>
          <Routes>
            <Route path="/auth/*" element={<Auth />} />
            <PrivateRoute path="/*" element={<AuthenticatedAppRouter />} />
          </Routes>
        </AuthProvider>
      </FetchProvider>
    </BrowserRouter>
  );
}

export default App;
