import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import {
  Category,
  ChartComponent,
  Crosshair,
  DateTime,
  Inject,
  Legend,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import { Amplify } from "aws-amplify";
import * as React from "react";

import {
  flowMpsOptions,
  flowVolOptions,
  presPasOptions,
  presPsiOptions,
  tempCOptions,
  tempFOptions,
  tempVoptions,
  vibrationCelsiusOptions,
  vibrationCrestOptions,
  vibrationFahrenheitOptions,
  vibrationHZOptions,
  vibrationMMSOptions,
} from "../../constants";

function vibrationAxisParser(measure) {
  switch (measure) {
    case "HZ x-axis":
    case "HZ y-axis":
    case "HZ z-axis":
      return vibrationHZOptions;

    case "mms x-axis":
    case "mms y-axis":
    case "mms z-axis":
      return vibrationMMSOptions;

    case "crest factor x-axis":
    case "crest factor y-axis":
    case "crest factor z-axis":
      return vibrationCrestOptions;

    case "temperature fahrenheit":
      return vibrationFahrenheitOptions;

    case "temperature celsius":
      return vibrationCelsiusOptions;

    case "Duty Cycle":
      return vibrationCrestOptions;

    default:
      break;
  }
}



function setYAxis(type, measure) {
  if (type === "Temperature") {
    if (measure === "Fahrenheit") {
      return tempFOptions;
    } else if (measure === "Voltage") {
      return tempVoptions;
    } else {
      return tempCOptions;
    }
  } else if (type === "Flow") {
    if (measure === "Voltage") {
      return flowVolOptions;
    } else {
      return flowMpsOptions;
    }
  } else if (type === "Pressure") {
    if (measure === "PSI") {
      return presPsiOptions;
    } else {
      return presPasOptions;
    }
  } else {
    return vibrationAxisParser(measure);
  }
}

function tempParser(value, type) {
  return type === "Fahrenheit"
    ? value * 7.82
    : type === "Voltage"
    ? value
    : (5 / 9) * (value * 7.82 - 32);
}

function pressureParser(value, type) {
  return type === "pascal" ? value * 6.89476 : value;
}

function flowParser(value, type) {
  return type === "Voltage" ? value : 0.199 * value;
}

function vibrationValueParser(values, measurementType) {
  switch (measurementType) {
    case "HZ x-axis":
      return values[3];
    case "HZ y-axis":
      return values[4];
    case "HZ z-axis":
      return values[5];

    case "mm/s x-axis":
      return values[0];
    case "mm/s y-axis":
      return values[1];
    case "mm/s z-axis":
      return values[2];

    case "crestFactor x-axis":
      return values[9];
    case "crestFactor y-axis":
      return values[10];
    case "crestFactor z-axis":
      return values[11];

    case "temperature fahrenheit":
      return (values[7] * 9) / 5 + 32;
    case "temperature celsius":
      return values[7];

    case "DutyCycle":
      return values[8];

    default:
      break;
  }
}
function handleParsing(values, deviceType, measurementType) {
  //return parser based on type
  if (deviceType === "Temperature") {
    return tempParser(values[0], measurementType);
  } else if (deviceType === "Vibration") {
    return vibrationValueParser(values, measurementType);
  } else if (deviceType === "Pressure") {
    return pressureParser(values[0], measurementType);
  } else if (deviceType === "Flow") {
    return flowParser(values[0], measurementType);
  }
}

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:f2bfd11c-873e-49a2-a8d8-50b2a9b62052",
    region: "us-east-1",
    userPoolId: "us-east-1_2C3lj4szbnCD",
    userPoolWebClientId: "18d5eg2megc0b26auk8c1kk5du",
  },
});

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "us-east-1",
    aws_pubsub_endpoint: `wss://a1qpms3dtf3x1j-ats.iot.${"us-east-1"}.amazonaws.com/mqtt`,
  })
);

const initialState = {
  series: [],
  sequenceLookup: {},
  yAxis: { majorTickLines: { width: 0 }, minorTickLines: { width: 0 } },
};

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case 0:
      return {
        ...state,
        sequenceLookup: { ...state.sequenceLookup, [payload.sequence]: 0 },
        series:
          payload.sequence in state.sequenceLookup
            ? state.series
            : state.series.length >= 30
            ? [
                ...state.series.slice(1),
                { x: payload.sequence, y: payload.value },
              ]
            : [...state.series, { x: payload.sequence, y: payload.value }],
      };

    case 1:
      return initialState;

    case 2:
      return {
        ...state,
        yAxis: setYAxis(payload.type, payload.selectedMeasure),
      };
    default:
      return state;
  }
}
export default function DynamicLine({ selectedMeasure, type, deviceId }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    //clear series on type change
    dispatch({ type: 1 });
    //set yaxis
    dispatch({
      type: 2,
      payload: { type, selectedMeasure: selectedMeasure.name },
    });

    const subscription = Amplify.PubSub.subscribe(
      "iot_device_analytics"
    ).subscribe({
      next: (data) => {
        //parse the message
        const parsed = JSON.parse(data.value.message);

        if (parsed.d && parsed.d.Sensor_ID === deviceId) {
          dispatch({
            type: 0,
            payload: {
              value: handleParsing(parsed.d.Values, type, selectedMeasure.name),
              sequence: data.value.sequence,
            },
          });
        }
      },
      error: (error) => console.error(error),
      close: () => console.log("Done"),
    });

    return () => subscription.unsubscribe();
  }, [selectedMeasure, deviceId, type]);

  return (
    <ChartComponent
      id={`charts-${deviceId}`}
      primaryXAxis={{
        // valueType: "Category",
        //labelFormat: "y",
        //edgeLabelPlacement: "Shift",
        visible: false,
        majorGridLines: { width: 0 },
      }}
      primaryYAxis={state.yAxis ? state.yAxis : {}}
      height={"400px"}
    >
      <Inject
        services={[LineSeries, DateTime, Category, Legend, Tooltip, Crosshair]}
      />
      <SeriesCollectionDirective>
        <SeriesDirective
          dataSource={state.series}
          xName="x"
          yName="y"
          type="Line"
        ></SeriesDirective>
      </SeriesCollectionDirective>
    </ChartComponent>
  );
}
