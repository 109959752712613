import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";
import SignIn from "./SignIn";
import Verification from "./Verification";

export default function Auth() {
  return (
    <Routes>
      <PrivateRoute unauthenticated path="login" element={<SignIn />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="verification" element={<Verification />} />
      <Route path="change-password" element={<ChangePassword />} />
    </Routes>
  );
}
