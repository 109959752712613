export const vibrationHZOptions = {
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelFormat: "{value} ",
  rangePadding: "None",
  lineStyle: { width: 8 },
  minimum: 0,
  maximum: 800,
  interval: 100,
};

export const vibrationMMSOptions = {
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelFormat: "{value} ",
  rangePadding: "None",
  lineStyle: { width: 8 },
  minimum: 0,
  maximum: 12,
  interval: 0.5,
};

export const vibrationCrestOptions = {
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelFormat: "{value} ",
  rangePadding: "None",
  lineStyle: { width: 8 },
  minimum: 0,
  maximum: 12,
  interval: 0.5,
};

export const vibrationFahrenheitOptions = {
  labelFormat: "{value} ",
  rangePadding: "None",
  lineStyle: { width: 8 },
  minimum: 0,
  maximum: 80,
  interval: 5,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const vibrationCelsiusOptions = {
  labelFormat: "{value} ",
  rangePadding: "None",
  lineStyle: { width: 8 },
  minimum: 0,
  maximum: 40,
  interval: 5,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const tempFOptions = {
  rangePadding: "None",
  lineStyle: { width: 8 },
  minimum: 66,
  maximum: 80,
  interval: 1,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const tempVoptions = {
  labelFormat: "{value} ",
  rangePadding: "None",
  lineStyle: { width: 8 },
  minimum: 0,
  maximum: 50,
  interval: 10,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const tempCOptions = {
  labelFormat: "{value} ",
  rangePadding: "None",

  lineStyle: { width: 2 },
  minimum: 20,
  maximum: 28,
  interval: 0.5,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

//pressure chart options
export const presPasOptions = {
  labelFormat: "{value} ",
  rangePadding: "None",
  lineStyle: { width: 2 },
  minimum: 40_000,
  maximum: 80000,
  interval: 10_000,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const presPsiOptions = {
  labelFormat: "{value} ",
  rangePadding: "None",
  lineStyle: { width: 8 },
  minimum: 0,
  maximum: 12,
  interval: 0.5,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const flowVolOptions = {
  labelFormat: "{value} ",
  rangePadding: "None",
  lineStyle: { width: 8 },
  minimum: 0,
  maximum: 12,
  interval: 0.5,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const flowMpsOptions = {
  labelFormat: "{value} ",
  rangePadding: "None",
  lineStyle: { width: 2 },
  minimum: 0,
  maximum: 12,
  interval: 0.5,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};
