import { ChevronLeftIcon } from "@heroicons/react/solid";
import React, { useRef } from "react";
import { IconContext } from "react-icons";
import { BiWater } from "react-icons/bi";
import { FaTemperatureLow } from "react-icons/fa";
import { GiCampCookingPot, GiVibratingBall } from "react-icons/gi";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Floor from "./Floor";

export default function Building() {
  const location = useLocation();

  const navigate = useNavigate();

  //we lose the location on renders, store in a ref
  const locationRef = useRef(location);

  return (
    <Routes>
      <Route
        caseSensitive
        path="/:building"
        element={
          <div className="flex justify-around py-4">
            <div className="w-7/12">
              <button
                onClick={() => navigate(-1)}
                className="mb-4 capitalize flex items-center text-gray-500"
              >
                <ChevronLeftIcon className="h-5 w-5 text-gray-900" />
                <p>Back to locations</p>
              </button>

              {Object.keys(locationRef.current.state.floors).map((floor) => (
                <div
                  key={floor}
                  className="bg-white rounded-lg shadow px-2 py-2 w-full mb-4"
                >
                  <div className="flex justify-between items-center mb-6">
                    <h3 className="text-gray-900 font-medium text-lg">
                      {floor}
                    </h3>
                    <button
                      className="bg-green-500 text-white py-1 px-2 rounded font-medium shadow"
                      onClick={() =>
                        navigate(
                          `${locationRef.current.pathname}/${locationRef.current.state.floors[floor].navTitle}`,
                          {
                            state: {
                              building: locationRef.current.state.building,
                              selected: locationRef.current.state.selected,
                              floor: locationRef.current.state.floors[floor],
                            },
                          }
                        )
                      }
                    >
                      View Details{" "}
                    </button>
                  </div>
                  <div className="flex space-x-12 px-12">
                    {locationRef.current.state.floors[floor].devices.map(
                      (device) => {
                        return (
                          <div key={device.friendly_name}>
                            {device.friendly_name === "IFM Temp" && (
                              <div className="relative">
                                <IconContext.Provider
                                  value={{
                                    className: "text-gray-600 h-11 w-11",
                                  }}
                                >
                                  <FaTemperatureLow />
                                </IconContext.Provider>
                                <div
                                  className={`h-4 w-4 bg-${
                                    device.worstStatus === 1
                                      ? "green"
                                      : device.worstStatus === 2
                                      ? "yellow"
                                      : "red"
                                  }-500 rounded-full absolute bottom-0 right-0`}
                                ></div>
                              </div>
                            )}

                            {device.friendly_name === "IFM Flow" && (
                              <div className="relative">
                                <IconContext.Provider
                                  value={{
                                    className: "text-gray-600 h-11 w-11",
                                  }}
                                >
                                  <BiWater />
                                </IconContext.Provider>
                                <div
                                  className={`h-4 w-4 bg-${
                                    device.worstStatus === 1
                                      ? "green"
                                      : device.worstStatus === 2
                                      ? "yellow"
                                      : "red"
                                  }-500 rounded-full absolute bottom-0 right-0`}
                                ></div>
                              </div>
                            )}

                            {device.friendly_name === "Pressure" && (
                              <div className="relative">
                                <IconContext.Provider
                                  value={{
                                    className: "text-gray-600 h-11 w-11",
                                  }}
                                >
                                  <GiCampCookingPot />
                                </IconContext.Provider>
                                <div
                                  className={`h-4 w-4 bg-${
                                    device.worstStatus === 1
                                      ? "green"
                                      : device.worstStatus === 2
                                      ? "yellow"
                                      : "red"
                                  }-500 rounded-full absolute bottom-0 right-0`}
                                ></div>
                              </div>
                            )}
                            {device.friendly_name === "Vibration Meter" && (
                              <div className="relative">
                                <IconContext.Provider
                                  value={{
                                    className: "text-gray-600 h-11 w-11",
                                  }}
                                >
                                  <GiVibratingBall />
                                </IconContext.Provider>
                                <div
                                  className={`h-4 w-4 bg-${
                                    device.worstStatus === 1
                                      ? "green"
                                      : device.worstStatus === 2
                                      ? "yellow"
                                      : "red"
                                  }-500 rounded-full absolute bottom-0 right-0`}
                                ></div>
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="bg-white rounded-lg shadow w-64 flex flex-col justify-between py-4">
              <div className="px-4">
                {/* {selected.location !== "" && (
          <button
            onClick={() =>
              setSelected({
                location: "",
                buildings: [{ name: "", deviceCount: 0 }],
              })
            }
            className="flex items-center space-x-2 mb-4"
          >
            <ArrowNarrowLeftIcon className="h-4 w-4 text-gray-500" />
            <p className="text-sm text-gray-500">Go Back</p>
          </button>
        )} */}
                <h2 className="text-gray-900 font-bold mb-4">
                  {locationRef.current.state.building}
                </h2>
                {Object.keys(locationRef.current.state.floors).map((floor) => (
                  <h2 key={floor} className="text-gray-900 font-bold mb-4">
                    {floor}{" "}
                    <span
                      className={`italic text-${
                        location.state.selected.worstStatus === 1
                          ? "green"
                          : location.state.selected.worstStatus === 2
                          ? "yellow"
                          : "red"
                      }-500 font-normal`}
                    >
                      {locationRef.current.state.floors[floor].devices.length}{" "}
                      devices
                    </span>
                  </h2>
                ))}
              </div>

              <div className="flex justify-center items-center space-x-3  ">
                <span className="rounded py-1 px-2 font-medium text-white bg-green-500">
                  Good
                </span>
                <span className="rounded py-1 px-2 font-medium text-white bg-yellow-500">
                  Issues
                </span>
                <span className="rounded py-1 px-2 font-medium text-white bg-red-500">
                  Critical
                </span>
              </div>
            </div>
          </div>
        }
      />

      <Route caseSensitive path=":building/*" element={<Floor />} />
    </Routes>
  );
}
