import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import * as AWS from "aws-sdk";
import React, { Fragment, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Line } from "./Line";
import Select from "./Select";
import Spinner from "./ui/Spinner";

//TODO:based on what the user selects, use the lookup table to find the device id, from there pass the id into another function to grab its data
function Example({ setTitles, selectableDevices, showSpinner, spinnerColor }) {
  return (
    <Listbox>
      {({ open }) => (
        <>
          <Listbox.Label className="text-sm font-medium text-gray-700 flex">
            Selected Devices
            {showSpinner && <Spinner color={spinnerColor} />}
          </Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm">
              <span className="block truncate">Selected</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                <fieldset className="space-y-5 ml-2">
                  <legend className="sr-only">Notifications</legend>
                  {selectableDevices?.map((device) => (
                    <div
                      key={device.device_id}
                      className="relative flex items-start"
                    >
                      <div className="flex items-center h-5">
                        <input
                          id={device.device_id}
                          aria-describedby="comments-description"
                          name={device.device_id}
                          type="checkbox"
                          defaultChecked={true}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setTitles((titles) => [
                                ...titles,
                                device.device_id,
                              ]);
                            } else {
                              setTitles((titles) =>
                                titles.filter((e) => e !== device.device_id)
                              );
                            }
                          }}
                          className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-700"
                        >
                          {device.device_id}
                        </label>
                      </div>
                    </div>
                  ))}
                </fieldset>
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

const measureOptionsTemp = [
  { id: 0, name: "Fahrenheit" },
  { id: 1, name: "Celsius" },
  { id: 2, name: "Voltage" },
];

const deviceOptions = [
  { id: 0, name: "Temperature" },
  { id: 1, name: "Pressure" },
  { id: 2, name: "Flow" },
  { id: 3, name: "Vibration" },
];

const measureOptionsPressure = [
  { id: 0, name: "PSI" },
  { id: 1, name: "Pascal" },
];

const measureOptionsFlow = [
  { id: 0, name: "Voltage" },
  { id: 1, name: "MPS" },
];

const measureOptionsVibration = [
  { id: 0, name: "HZ x-axis" },
  { id: 1, name: "HZ y-axis" },
  { id: 2, name: "HZ z-axis" },
  { id: 3, name: "mms x-axis" },
  { id: 4, name: "mms y-axis" },
  { id: 5, name: "mms x-axis" },
  { id: 6, name: "crest factor x-axis" },
  { id: 7, name: "crest factor y-axis" },
  { id: 8, name: "crest factor z-axis" },
  { id: 9, name: "temperature fahrenheit" },
  { id: 10, name: "temperature celsius" },
  { id: 11, name: "Duty Cycle" },
];

const docClient = new AWS.DynamoDB.DocumentClient({
  region: "us-east-1",
  secretAccessKey: "RiCC03NgsNxaXCyABD5nJhGNiK1lrtKxHt5ivCTu",
  accessKeyId: "AKIAZVWOKWYTOHKLQ7KG",
});

function get(startDate, endDate, deviceId, lastEvaluatedKey = "") {
  const formattedStart = startDate.setUTCHours(0, 0, 0, 0);
  const formattedEnd = endDate.setUTCHours(23, 59, 59, 999);

  const param = {};
  if (lastEvaluatedKey !== "") {
    param.ExclusiveStartKey = {
      sequence: lastEvaluatedKey,
      sequence_sort: lastEvaluatedKey,
    };
  }

  return new Promise((resolve, reject) => {
    docClient.scan(
      {
        TableName: "updated_aquatherm_v2",
        FilterExpression:
          "contains(message.message, :deviceId) and #sequence between :start_ts and :end_ts",
        ExpressionAttributeNames: {
          "#sequence": "sequence",
        },
        ExpressionAttributeValues: {
          ":deviceId": deviceId,
          ":start_ts": `${formattedStart}`,
          ":end_ts": `${formattedEnd}`,
        },
        ...param,
      },
      function (err, data) {
        if (err) reject(err);
        resolve(data);
      }
    );
  });
}

function getDeviceId(deviceType) {
  let type;
  if (deviceType === "Temperature") {
    type = "IFM Temp";
  } else if (deviceType === "Pressure") {
    type = "Pressure";
  } else if (deviceType === "Vibration") {
    type = "Vibration Meter";
  } else if (deviceType === "Flow") {
    type = "IFM Flow";
  }

  return new Promise((resolve, reject) => {
    docClient.scan(
      {
        TableName: "lookup_table",
        FilterExpression: "friendly_name = :deviceType",

        ExpressionAttributeValues: {
          ":deviceType": type,
        },
      },
      function (err, data) {
        if (err) reject(err);
        resolve(data);
      }
    );
  });
}

export default function Report() {
  const [dataSets, setDataDataSets] = useState({
    Temperature: {},
    Pressure: {},
    Vibration: {},
    Flow: {},
  });
  const [startDate, setStartDate] = useState(new Date("2021-11-11"));
  const [endDate, setEndDate] = useState(new Date("2021-11-11"));
  const [selectedDevice, setSelectedDevice] = useState(deviceOptions[1]);
  const [selectedPressure, setSelectedPressure] = useState(
    measureOptionsPressure[0]
  );
  const [selectedFlow, setSelectedFlow] = useState(measureOptionsFlow[0]);
  const [selectedVibration, setSelectedVibration] = useState(
    measureOptionsVibration[0]
  );
  const [selectableDevices, setSelectableDevices] = useState({});
  const [selected, setSelected] = useState(measureOptionsTemp[0]);
  const [titles, setTitles] = useState([]);
  const [status, setStatus] = useState("IDLE");
  const [refresh, setRefresh] = useState(false);

  const searchInfo = useRef();

  useEffect(() => {
    if (searchInfo.current) {
      if (
        searchInfo.current.startDate !== startDate ||
        searchInfo.current.endDate !== endDate ||
        searchInfo.current.selectedDevice.name !== selectedDevice.name
      ) {
        setRefresh(false);
      }
    }
  }, [searchInfo, startDate, endDate, selectedDevice]);

  useEffect(() => {
    let interval;
    if (refresh) {
      interval = setInterval(() => {
        handleSearch();
      }, 2000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [refresh]);

  async function handleSelectedDevice(e) {
    try {
      setStatus("UPDATING_SELECTED_DEVICE");
      setSelectedDevice(e);

      const res = await getDeviceId(e.name);

      setSelectableDevices((old) => {
        if (old[e.name]) {
          return {
            ...old,
            [e.name]: {
              devices: res.Items,
            },
          };
        } else {
          return {
            [e.name]: {
              devices: res.Items,
            },
          };
        }
      });

      setTitles(res.Items.map((e) => e.device_id));

      setStatus("SELECTED_DEVICE_UPDATED");
    } catch (error) {}
  }

  async function handleSearch() {
    try {
      setStatus("SEARCHING");

      //for every selectable device grab its data
      for (const item of titles) {
        let x = await get(startDate, endDate, item);

        //set data
        const parsed = x.Items.map((e) => {
          const parsedMessage = JSON.parse(e.message.message);

          return {
            timeStamp: e.message.ts,
            message: parsedMessage,
          };
        });

        let i = 0;
        while (true) {
          i++;
          try {
            const s = await get(
              startDate,
              endDate,
              item,
              x.LastEvaluatedKey.sequence
            );

            parsed.push(
              ...s.Items.map((e) => {
                const parsedMessage = JSON.parse(e.message.message);

                return {
                  timeStamp: e.message.ts,
                  message: parsedMessage,
                };
              })
            );

            if (!s.LastEvaluatedKey.sequence) {
              break;
            } else {
              x = s;
            }

            if (i === 3) break;
          } catch (error) {
            break;
          }
        }

        setDataDataSets((old) => {
          return {
            ...old,
            [selectedDevice.name]: {
              ...old[selectedDevice.name],
              [item]: {
                items: parsed,
              },
            },
          };
        });
      }

      setStatus("SEARCH_RESOLVED");

      // if (!refresh) {
      //   //store current search info
      //   searchInfo.current = {
      //     startDate,
      //     endDate,
      //     selectedDevice,
      //   };

      //   setRefresh(true);
      // }
    } catch (error) {}
  }

  return (
    <div className="h-screen px-4 pt-4">
      <div className="flex">
        <Link
          to="/locations"
          type="button"
          className="inline-flex mr-4 mb-2 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Locations
        </Link>
        <p className="text-gray-700 font-medium text-2xl mb-3">
          Aquatherm Test Pipe, Lindon, UT
        </p>
      </div>

      <div className="flex justify-between">
        <div className="flex space-x-2">
          <div>
            <label
              htmlFor="startDate"
              className="block font-medium text-gray-700 text-sm"
            >
              Start Date
            </label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>

          <div>
            <label
              htmlFor="endDate"
              className="block font-medium text-gray-700 text-sm"
            >
              End Date
            </label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>

          <div className="w-64">
            <Select
              labelText="Selected Device"
              selected={selectedDevice}
              setSelected={handleSelectedDevice}
              options={deviceOptions}
            />
          </div>
          <div className="w-64">
            <Select
              labelText="Unit Type"
              selected={
                selectedDevice.id === 0
                  ? selected
                  : selectedDevice.id === 1
                  ? selectedPressure
                  : selectedDevice.id === 2
                  ? selectedFlow
                  : selectedVibration
              }
              setSelected={
                selectedDevice.id === 0
                  ? setSelected
                  : selectedDevice.id === 1
                  ? setSelectedPressure
                  : selectedDevice.id === 2
                  ? setSelectedFlow
                  : setSelectedVibration
              }
              options={
                selectedDevice.id === 0
                  ? measureOptionsTemp
                  : selectedDevice.id === 1
                  ? measureOptionsPressure
                  : selectedDevice.id === 2
                  ? measureOptionsFlow
                  : measureOptionsVibration
              }
            />
          </div>
          <div className="w-64">
            <Example
              titles={titles}
              setTitles={setTitles}
              spinnerColor="green"
              showSpinner={status === "UPDATING_SELECTED_DEVICE"}
              selectableDevices={
                selectableDevices[selectedDevice.name]?.devices
              }
            />
          </div>
          <div className="flex flex-col items-center justify-end">
            <button
              onClick={handleSearch}
              disabled={refresh || (!refresh && status === "SEARCHING")}
              className="bg-green-500 text-white font-medium rounded px-3 py-2 shadow-sm"
            >
              {!refresh && status === "SEARCHING" && <Spinner />}
              {!refresh && status !== "SEARCHING" && "Search"}
              {refresh && "Search"}
            </button>
          </div>
        </div>
      </div>
      {refresh && (
        <div className="flex mt-2">
          <p className="text-gray-500 italic">
            Chart is now updating every two seconds.
          </p>
          <p
            className="ml-2 text-green-500 underline"
            onClick={() => setRefresh(false)}
          >
            Click To Cancel Reloading
          </p>

          {status === "SEARCHING" && <Spinner color="green" />}
        </div>
      )}
      <div className="grid grid-cols-1 gap-2">
        <div
          className="bg-white shadow rounded px-2 pt-2 mt-4"
          style={{ height: "800px" }}
        >
          {selectedDevice.id === 0 && (
            <Temperature
              selected={selected}
              dataSets={dataSets}
              titles={titles}
            />
          )}
          {selectedDevice.id === 1 && (
            <Pressure
              selected={selectedPressure}
              dataSets={dataSets}
              titles={titles}
            />
          )}
          {selectedDevice.id === 2 && (
            <Flow selected={selectedFlow} dataSets={dataSets} titles={titles} />
          )}

          {selectedDevice.id === 3 && (
            <Vibration
              selected={selectedVibration}
              dataSets={dataSets}
              titles={titles}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function Temperature({ titles, selected, dataSets }) {
  const [parsed, setParsed] = useState({});

  useEffect(() => {
    function testing() {
      const idk = {};
      for (const dataSet in dataSets.Temperature) {
        if (!(dataSets.Temperature[dataSet] in idk)) {
          idk[dataSet] = { fahrenheit: [], celsius: [], voltage: [] };
        }
        for (const item of dataSets.Temperature[dataSet].items) {
          if (!item.message.d) continue;
          const f = item.message.d.Values[0];
          idk[dataSet].fahrenheit.push({
            x: item.timeStamp,
            y: f * 7.82,
          });
          idk[dataSet].celsius.push({
            x: item.timeStamp,
            y: (5 / 9) * (f * 7.82 - 32),
          });
          idk[dataSet].voltage.push({
            x: item.timeStamp,
            y: f,
          });
        }
        idk[dataSet].fahrenheit.sort((a, z) => new Date(a.x) - new Date(z.x));
        idk[dataSet].celsius.sort((a, z) => new Date(a.x) - new Date(z.x));
        idk[dataSet].voltage.sort((a, z) => new Date(a.x) - new Date(z.x));
      }

      setParsed(idk);
    }
    testing();
  }, [dataSets]);

  return (
    <>
      {selected.id === 0 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="fahrenheit"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 8 },
            minimum: 70,
            maximum: 80,
            interval: 0.5,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}

      {selected.id === 1 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="celsius"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 2 },
            minimum: 20,
            maximum: 28,
            interval: 0.5,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
      {selected.id === 2 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="voltage"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",
            lineStyle: { width: 2 },
            minimum: 9,
            maximum: 10,
            interval: 0.5,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
    </>
  );
}

function Pressure({ titles, selected, dataSets }) {
  const [parsed, setParsed] = useState({});

  useEffect(() => {
    function testing() {
      const idk = {};
      for (const dataSet in dataSets.Pressure) {
        if (!(dataSets.Pressure[dataSet] in idk)) {
          idk[dataSet] = { PSI: [], Pascal: [] };
        }
        for (const item of dataSets.Pressure[dataSet].items) {
          const f = item.message.d.Values[0];
          idk[dataSet].PSI.push({
            x: item.timeStamp,
            y: f,
          });
          idk[dataSet].Pascal.push({
            x: item.timeStamp,
            y: f * 6.89476,
          });
        }

        //TODO:sort here
      }

      setParsed(idk);
    }
    testing();
  }, [dataSets]);
  return (
    <>
      {selected.id === 0 ? (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="PSI"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 12,
            interval: 0.5,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      ) : (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="Pascal"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 2 },
            minimum: 40_000,
            maximum: 80000,
            interval: 10_000,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
    </>
  );
}

function Flow({ titles, selected, dataSets }) {
  const [parsed, setParsed] = useState({});

  useEffect(() => {
    function testing() {
      const idk = {};
      for (const dataSet in dataSets.Flow) {
        if (!(dataSets.Flow[dataSet] in idk)) {
          idk[dataSet] = { Voltage: [], MPS: [] };
        }
        for (const item of dataSets.Flow[dataSet].items) {
          const f = item.message.d.Values[0];
          idk[dataSet].Voltage.push({
            x: item.timeStamp,
            y: f,
          });
          idk[dataSet].MPS.push({
            x: item.timeStamp,
            y: 0.199 * f,
          });
        }
        idk[dataSet].Voltage.sort((a, z) => new Date(a.x) - new Date(z.x));
        idk[dataSet].MPS.sort((a, z) => new Date(a.x) - new Date(z.x));
        //TODO:sort here
      }

      setParsed(idk);
    }
    testing();
  }, [dataSets]);

  /*TODO set min and max values for flow*/
  return (
    <>
      {selected.id === 0 ? (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="Voltage"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 12,
            interval: 0.5,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      ) : (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="MPS"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 2 },
            minimum: 0,
            maximum: 12,
            interval: 3,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
    </>
  );
}
function Vibration({ titles, selected, dataSets }) {
  const [parsed, setParsed] = useState({});

  useEffect(() => {
    function testing() {
      const idk = {};
      for (const dataSet in dataSets.Vibration) {
        if (!(dataSets.Vibration[dataSet] in idk)) {
          idk[dataSet] = {
            "HZ x-axis": [],
            "HZ y-axis": [],
            "HZ z-axis": [],
            "mm/s x-axis": [],
            "mm/s y-axis": [],
            "mm/s z-axis": [],
            "crestFactor x-axis": [],
            "crestFactor y-axis": [],
            "crestFactor z-axis": [],
            "temperature fahrenheit": [],
            "temperature celsius": [],
            DutyCycle: [],
          };
        }
        for (const item of dataSets.Vibration[dataSet].items) {
          idk[dataSet]["HZ x-axis"].push({
            x: item.timeStamp,
            y: item.message.d.Values[3],
          });

          idk[dataSet]["HZ y-axis"].push({
            x: item.timeStamp,
            y: item.message.d.Values[4],
          });

          idk[dataSet]["HZ z-axis"].push({
            x: item.timeStamp,
            y: item.message.d.Values[5],
          });

          idk[dataSet]["mm/s x-axis"].push({
            x: item.timeStamp,
            y: item.message.d.Values[0],
          });

          idk[dataSet]["mm/s y-axis"].push({
            x: item.timeStamp,
            y: item.message.d.Values[1],
          });

          idk[dataSet]["mm/s z-axis"].push({
            x: item.timeStamp,
            y: item.message.d.Values[2],
          });

          idk[dataSet]["crestFactor x-axis"].push({
            x: item.timeStamp,
            y: item.message.d.Values[9],
          });
          idk[dataSet]["crestFactor y-axis"].push({
            x: item.timeStamp,
            y: item.message.d.Values[10],
          });
          idk[dataSet]["crestFactor z-axis"].push({
            x: item.timeStamp,
            y: item.message.d.Values[11],
          });

          idk[dataSet]["temperature fahrenheit"].push({
            x: item.timeStamp,
            y: (item.message.d.Values[7] * 9) / 5 + 32,
          });

          idk[dataSet]["temperature celsius"].push({
            x: item.timeStamp,
            y: item.message.d.Values[7],
          });

          idk[dataSet].DutyCycle.push({
            x: item.timeStamp,
            y: item.message.d.Values[8],
          });
        }

        /**SORT */
        idk[dataSet]["HZ x-axis"].sort((a, z) => new Date(a.x) - new Date(z.x));
        idk[dataSet]["HZ y-axis"].sort((a, z) => new Date(a.x) - new Date(z.x));
        idk[dataSet]["HZ z-axis"].sort((a, z) => new Date(a.x) - new Date(z.x));

        idk[dataSet]["mm/s x-axis"].sort(
          (a, z) => new Date(a.x) - new Date(z.x)
        );
        idk[dataSet]["mm/s y-axis"].sort(
          (a, z) => new Date(a.x) - new Date(z.x)
        );
        idk[dataSet]["mm/s z-axis"].sort(
          (a, z) => new Date(a.x) - new Date(z.x)
        );

        idk[dataSet]["crestFactor x-axis"].sort(
          (a, z) => new Date(a.x) - new Date(z.x)
        );
        idk[dataSet]["crestFactor y-axis"].sort(
          (a, z) => new Date(a.x) - new Date(z.x)
        );
        idk[dataSet]["crestFactor z-axis"].sort(
          (a, z) => new Date(a.x) - new Date(z.x)
        );

        idk[dataSet]["temperature fahrenheit"].sort(
          (a, z) => new Date(a.x) - new Date(z.x)
        );
        idk[dataSet]["temperature celsius"].sort(
          (a, z) => new Date(a.x) - new Date(z.x)
        );

        idk[dataSet].DutyCycle.sort((a, z) => new Date(a.x) - new Date(z.x));
      }
      setParsed(idk);
    }
    testing();
  }, [dataSets]);

  return (
    <>
      {selected.id === 0 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="HZ x-axis"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 800,
            interval: 100,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
      {selected.id === 1 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="HZ y-axis"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 800,
            interval: 100,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
      {selected.id === 2 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="HZ z-axis"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 800,
            interval: 100,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}

      {selected.id === 3 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="mm/s x-axis"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 0.5,
            interval: 0.05,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
      {selected.id === 4 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="mm/s y-axis"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 12,
            interval: 0.5,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
      {selected.id === 5 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="mm/s z-axis"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 12,
            interval: 0.5,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
      {selected.id === 6 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="crestFactor x-axis"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 12,
            interval: 0.5,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
      {selected.id === 7 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="crestFactor y-axis"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 12,
            interval: 0.5,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
      {selected.id === 8 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="crestFactor z-axis"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 12,
            interval: 0.5,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
      {selected.id === 9 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="temperature fahrenheit"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",

            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 80,
            interval: 5,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
      {selected.id === 10 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="temperature celsius"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",
            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 40,
            interval: 5,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
      {selected.id === 11 && (
        <Line
          key={titles}
          titles={titles}
          dataSets={parsed}
          type="DutyCycle"
          id="Processing"
          xAxisOptions={{
            valueType: "Category",
            labelFormat: "y",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
          }}
          yAxisOptions={{
            labelFormat: "{value} ",
            rangePadding: "None",
            lineStyle: { width: 8 },
            minimum: 0,
            maximum: 12,
            interval: 0.5,
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
        />
      )}
    </>
  );
}
